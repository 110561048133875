import { Component, Renderer2 } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { PrimeNGConfig } from "primeng/api";
import { TabService } from "./services/tabmenu.service";
import { EnvconfigService } from "./envconfig.service";

@Component({
  selector: "app-main",
  templateUrl: "./app.main.component.html",
  styleUrls: ["./app.main.component.scss"],
  animations: [
    trigger("mask-anim", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      state(
        "visible",
        style({
          opacity: 0.8,
        })
      ),
      transition("* => *", animate("250ms cubic-bezier(0, 0, 0.2, 1)")),
    ]),
  ],
})
export class AppMainComponent {
  navigationItems: any = [];
  intermediateJson: any;
  anniversary_msg: any;
  anniversary_sub_msg: any;

  constructor(
    public renderer: Renderer2,
    private primengConfig: PrimeNGConfig,
    private tabservice: TabService,
    private API_content: EnvconfigService
  ) {
    this.tabservice.emptytabs();
  }

  ngOnInit() {
    this.intermediateJson = this.API_content.getContent();
    console.log(this.intermediateJson, "gvhbjk");

    this.anniversary_msg = this.intermediateJson.anniversary_msg;
    this.anniversary_sub_msg = this.intermediateJson.anniversary_sub_msg;

    this.navigationItems = this.intermediateJson.main_array;
  }

  navigate(linkurl: any) {
    if (linkurl !== "/") {
      window.open(linkurl, "_blank");
    }
    return;
  }
}
