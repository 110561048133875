import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable, BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class SharedDataService {
  private data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private tableColumnsData: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private envData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data$: Observable<any> = this.data.asObservable();
  tabledataColumns$: Observable<any> = this.tableColumnsData.asObservable();

  private loginPageLoader = new BehaviorSubject(false);
  isloginLoaderEnabled = this.loginPageLoader.asObservable();

  keyNames: any = [];
  columnWidthData: any = [];
  dateKeyNames: any = [];
  totalData = [];
  poolTableColumnsData: any = [];
  MnrTableColumnsData: any = [];
  reportSchedulingTableColumnsData: any = [];
  updateFmcaTableColumnsData: any = [];
  updateFmca_rebill_invoiceTableColumnsData: any = [];
  poolMasterTableColumnsData: any = [];
  poolModuleTableColumnsData: any = [];
  memberUsageTableColumnsData: any = [];
  ediErrorTableColumnsData: any = [];
  bwoqTableColumnsData: any = [];
  chs_migrationTableColumnsData: any = [];
  chs_onstreetBillingTableColumnsData: any = [];
  tableName: any = [];
  workorderSummaryTableColumnsData: any = [];
  workorderViewTableColumnsData: any = [];
  edi_ErrorTableColumnsData: any = [];
  edi_fileErrorTableColumnsData: any = [];
  BillableWorkOrderQueueTableColumnsData: any = [];
  BWOQ_viewBillableWorkOrderQueueTableColumnsData: any = [];
  BWOQ_BillableWorkOrderQueueTableColumnsData: any = [];
  BWOQ_nonBillableWorkOrderQueueTableColumnsData: any = [];
  poolBillingReportsTableColumnsData: any = [];
  salesOrderTableColumnsData: any = [];
  poolUtilizationSummaryTableColumnsData: any = [];
  rulesTableColumnsData: any = [];
  salesorderitemsTableColumnsData: any = [];
  salesorderitemdetaillsTableColumnsData: any = [];
  envURLs: any;
  vendoraccountstableData: any = [];
  wodoclinktableData: any = [];
  regulatoryComplianceData: any = [];
  numberFormateKeyNames: any = [];
  userPermissionData: any;
  Non_Dwelling_ReportColumnsData: any;
  overLapReports_ReportColumnsData: any;
  orderSummaryTableColumnsData: any = [];
  repoOrderSummaryTableColumnsData: any = [];
  orderActivitySummaryTableColumnsData: any = [];
  invoiceSummaryTableColumnsData: any = [];
  allTableColumnsData: any = [];
  approvalAllTableheadersColumnsData: any = [];
  selectedAllTableDataArrayHeadersColumnsData: any = [];
  vendorTableColumnsColumnsData: any = [];
  rateColumnsHeadersData: any = [];
  laneColumnsHeadersData: any = [];

  constructor() {}

  enableLoginLoaderState(message: boolean) {
    this.loginPageLoader.next(message);
  }

  setAppconfigData(data) {
    this.data.next(data);
  }
  setAppEnvronmentalURLconfigData(data) {
    this.envData.next(data);
  }
  settableConfigData(data) {
    this.tableColumnsData.next(data);
  }

  set costRelated_keyNames(data) {
    this.keyNames = data.ExcellNumberFormates.fileds;
  }

  set numberFormate_keyNames(data) {
    this.numberFormateKeyNames = data.numberFileds.fileds;
  }

  set environmentUrls(data) {
    this.envURLs = data;
  }

  get costRelated_keyNames() {
    return this.keyNames;
  }
  get numberFormate_keyNames() {
    return this.numberFormateKeyNames;
  }

  set DateRelated_keyNames(data) {
    this.dateKeyNames = data.ExcellDateFormates.fileds;
  }

  get DateRelated_keyNames() {
    return this.dateKeyNames;
  }
  get environmentURLs() {
    return this.envURLs;
  }

  set userAccessableData(data) {
    this.userPermissionData = data;
  }
  get userAccessableData() {
    return this.userPermissionData;
  }

  set tableCoulumData(data) {
    this.columnWidthData = data.fileds;
    this.MnrTableColumnsData = data.fileds;
    this.workorderSummaryTableColumnsData = data.workOrderSummary;
    this.orderActivitySummaryTableColumnsData = data.orderActivitySummary;
    this.workorderViewTableColumnsData = data.workOrderView;
    this.Non_Dwelling_ReportColumnsData = data.nonDwellingReport;
    this.overLapReports_ReportColumnsData = data.overLapReports;
    this.poolTableColumnsData = data.app_table;
    this.reportSchedulingTableColumnsData = data.reportScheduling;
    this.updateFmcaTableColumnsData = data.updateFmcaBit;
    this.updateFmca_rebill_invoiceTableColumnsData =
      data["updateFmcaBit-table"];
    this.poolMasterTableColumnsData = data.PoolMaster;
    this.poolModuleTableColumnsData = data.PoolModuleTable;
    this.memberUsageTableColumnsData = data.memberUsage;
    this.ediErrorTableColumnsData = data.EdiExceptions;
    this.edi_ErrorTableColumnsData = data.Edi_errors;
    this.edi_fileErrorTableColumnsData = data.Edi_file_errors;
    this.poolBillingReportsTableColumnsData = data.poolBillingReports;

    this.BillableWorkOrderQueueTableColumnsData =
      data.BWOQ_BillableWorkOrderQueue;
    this.BWOQ_nonBillableWorkOrderQueueTableColumnsData = data.BWOQ_nonBillable;
    this.BWOQ_BillableWorkOrderQueueTableColumnsData = data.BWOQ_billable;
    this.BWOQ_viewBillableWorkOrderQueueTableColumnsData =
      data.BWOQ_viewWorkorder;
    this.bwoqTableColumnsData = data.billableWorkOrder;

    this.salesOrderTableColumnsData = data.salesOrder;
    this.chs_migrationTableColumnsData = data.chs_migrationTable;
    this.chs_onstreetBillingTableColumnsData = data.onstreetBilling;
    this.poolUtilizationSummaryTableColumnsData = data.poolUtilizationSummary;
    this.salesorderitemsTableColumnsData = data.salesorderitems;
    this.salesorderitemdetaillsTableColumnsData = data.salesorderitemdetaills;
    this.rulesTableColumnsData = data.rules;
    this.vendoraccountstableData = data.vendoraccountstable;
    this.wodoclinktableData = data.wodoclinktable;
    this.regulatoryComplianceData = data.regulatoryCompliance;

    this.orderSummaryTableColumnsData = data.orderSummary;
    this.repoOrderSummaryTableColumnsData = data.repoOrderSummary;
    this.invoiceSummaryTableColumnsData = data.invoiceSummary;
    this.allTableColumnsData = data.allTableheaders;
    this.approvalAllTableheadersColumnsData = data.approvalAllTableheaders;
    this.selectedAllTableDataArrayHeadersColumnsData =
      data.selectedAllTableDataArrayHeaders;
    this.vendorTableColumnsColumnsData = data.vendorTableColumns;
    this.rateColumnsHeadersData = data.rateColumnsHeaders;
    this.laneColumnsHeadersData = data.laneColumnsHeaders;
  }

  // get columnnumbers to align
  getindexNumbers(keys: [], object: {}) {
    let indexes = [];
    keys.find((item) => {
      Object.keys(object).forEach((element, i) => {
        if (item == element) {
          indexes.push(i);
        }
      });
    });
    return indexes;
  }

  ExcellDataAlignments(tabledata: any, tabletype: string = "POOL") {
    let data = [];
    debugger;

    // let datakeys = [];
    // tabledata.forEach((keyname) => {
    // // datakeys = JSON.parse(JSON.stringify(tabledata).split('"amount":').join('"($) amount":'));
    //   datakeys = JSON.parse(
    //     JSON.stringify(tabledata).split(keyname).join(`${"($)"} ${keyname}`)
    //   );
    // });
    // tabledata = datakeys;

    data = tabledata.map((e, i) => {
      Object.keys(e).forEach((keyvalue) => {
        if (this.keyNames.indexOf(keyvalue?.toLocaleLowerCase()) >= 0) {
          if (
            (tabledata[i][keyvalue] || tabledata[i][keyvalue] == 0) &&
            typeof tabledata[i][keyvalue] == "string" &&
            tabledata[i][keyvalue].includes("$")
          ) {
            tabledata[i][keyvalue] =
              tabledata[i][keyvalue] === "NaN"
                ? 0
                : parseFloat(
                    tabledata[i][keyvalue]
                      .replace("$", "")
                      .replace(/,/g, "")
                      .trim()
                  );
          } else {
            tabledata[i][keyvalue] =
              tabledata[i][keyvalue] === "NaN" ? 0 : +tabledata[i][keyvalue];
          }
        }
        if (
          this.DateRelated_keyNames.indexOf(keyvalue?.toLocaleLowerCase()) >= 0
        ) {
          if (
            (tabledata[i][keyvalue] || tabledata[i][keyvalue] == 0) &&
            typeof tabledata[i][keyvalue] == "string"
          ) {
            tabledata[i][`${keyvalue}`] = this.formateDate(
              tabledata[i][keyvalue],
              tabletype
            );
            // tabledata[i][`${keyvalue}`] = tabledata[i][keyvalue]?.length > 12 ? moment(new Date(tabledata[i][keyvalue])).format("MM-DD-YYYY HH:mm:ss") :moment(new Date(tabledata[i][keyvalue])).format("MM-DD-YYYY") ;
          }
        }

        if (
          this.numberFormate_keyNames.indexOf(keyvalue?.toLocaleLowerCase()) >=
          0
        ) {
          if (
            (tabledata[i][keyvalue] || tabledata[i][keyvalue] == 0) &&
            typeof tabledata[i][keyvalue] == "string"
          ) {
            // tabledata[i][`${keyvalue}`] = tabledata[i][keyvalue] === 'NaN' ? 0 : parseFloat(tabledata[i][keyvalue]) ;
            tabledata[i][`${keyvalue}`] =
              tabledata[i][keyvalue] === "NaN"
                ? 0
                : tabledata[i][keyvalue] === ""
                ? ""
                : parseFloat(tabledata[i][keyvalue]);

            // tabledata[i][`${keyvalue}`] = tabledata[i][keyvalue]?.length > 12 ? moment(new Date(tabledata[i][keyvalue])).format("MM-DD-YYYY HH:mm:ss") :moment(new Date(tabledata[i][keyvalue])).format("MM-DD-YYYY") ;
          }
        }
      });
      return e;
    });
    return data;
  }

  // formateDate(date){
  //   if(date){
  //     let converted_date = date?.length > 12 ? moment.utc(date).toDate() :moment(new Date(date)).format("MM-DD-YYYY");
  //     // console.log('converted_date',converted_date)
  //     return converted_date
  //     // return date?.length > 12 ? new Date(Date.UTC( date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds() ) ) :moment(new Date(date)).format("MM-DD-YYYY")
  //     // return date?.length > 12 ? moment(new Date(date)).format("MM-DD-YYYY HH:mm:ss") :moment(new Date(date)).format("MM-DD-YYYY")
  //   }else{
  //     return ""
  //   }
  // }

  formateDate(date, tabletype) {
    if (date) {
      if (tabletype === "MNR") {
        return date?.length > 12
          ? moment.utc(date).toDate()
          : moment.utc(date).toDate();
      } else {
        // return date?.length > 12 ? moment.utc(date).toDate() :moment.utc(date).toDate()
        return date?.length > 12
          ? moment(new Date(date)).format("MM-DD-YYYY HH:mm:ss")
          : moment(new Date(date)).format("MM-DD-YYYY");
      }
    } else {
      return "";
    }
  }

  //   dateformate(value) {
  //     if (value !== "") {
  //         var effectiveDate = new Date(value);
  //         var month = effectiveDate.getMonth() + 1;
  //         var date = effectiveDate.getDate();
  //         var year = effectiveDate.getFullYear();
  //         return year + "-" + month + "-" + date;
  //     } else {
  //         return ""
  //     }
  // }
  // Sample
  get_tableKeysWithWidths(data) {
    var newValue = [];
    newValue = data.map((item) => {
      return { header: item, width: item?.length };
    });
  }

  //creating new array or objects with array of elements
  create_newArray(data, tableName = "mnr-module-table") {
    debugger;
    var newValue = [];
    this.columnWidthData = [];
    this.tableName = tableName;
    switch (tableName) {
      case "mnr-module-table":
        this.columnWidthData = this.MnrTableColumnsData;
        break;
      case "app-table":
        this.columnWidthData = this.poolTableColumnsData;
        break;
      case "reportscheduling-table":
        this.columnWidthData = this.reportSchedulingTableColumnsData;
        break;
      case "updateFmcaBit-table":
        this.columnWidthData = this.updateFmcaTableColumnsData;
        break;
      case "updateFmcaBit-table-rebil-invoice":
        this.columnWidthData = this.updateFmca_rebill_invoiceTableColumnsData;
        break;
      case "Pool-Module-table":
        this.columnWidthData = this.poolModuleTableColumnsData;
        break;
      case "memberUsage":
        this.columnWidthData = this.memberUsageTableColumnsData;
        break;
      case "EdiErrors":
        this.columnWidthData = this.edi_ErrorTableColumnsData;
        break;
      case "EdiFileErrors":
        this.columnWidthData = this.edi_fileErrorTableColumnsData;
        break;
      case "EdiExceptions":
        this.columnWidthData = this.ediErrorTableColumnsData;
        break;
      case "BWOQ":
        this.columnWidthData = this.bwoqTableColumnsData;
        break;
      case "BillableWorkOrderQueue":
        this.columnWidthData = this.BillableWorkOrderQueueTableColumnsData;
        break;
      case "BWOQ_nonBillableWorkOrderQueue":
        this.columnWidthData =
          this.BWOQ_nonBillableWorkOrderQueueTableColumnsData;
        break;
      case "BWOQ_BillableWorkOrderQueue":
        this.columnWidthData = this.BWOQ_BillableWorkOrderQueueTableColumnsData;
        break;
      case "BWOQ_viewBillableWorkOrderQueue":
        this.columnWidthData =
          this.BWOQ_viewBillableWorkOrderQueueTableColumnsData;
        break;
      case "salesOrder":
        this.columnWidthData = this.salesOrderTableColumnsData;
        break;
      case "chs_migration":
        this.columnWidthData = this.chs_migrationTableColumnsData;
        break;
      case "onstreetBilling":
        this.columnWidthData = this.chs_onstreetBillingTableColumnsData;
        break;
      case "PoolMaster":
        this.columnWidthData = this.poolMasterTableColumnsData;
        break;
      case "workOrderSummary":
        this.columnWidthData = this.workorderSummaryTableColumnsData;
        break;
      case "poolBillingReports":
        this.columnWidthData = this.poolBillingReportsTableColumnsData;
        break;
      case "poolUtilizationSummary":
        this.columnWidthData = this.poolUtilizationSummaryTableColumnsData;
        break;
      case "salesorderitemsgrid":
        this.columnWidthData = this.salesorderitemsTableColumnsData;
        break;
      case "salesorderitemdetaills":
        this.columnWidthData = this.salesorderitemdetaillsTableColumnsData;
        break;
      case "Rules":
        this.columnWidthData = this.rulesTableColumnsData;
        break;
      case "vendoraccountstable":
        this.columnWidthData = this.vendoraccountstableData;
        break;
      case "wodoclinktable":
        this.columnWidthData = this.wodoclinktableData;
        break;
      case "regulatoryCompliance":
        this.columnWidthData = this.regulatoryComplianceData;
        break;
      case "workorderItemView":
        this.columnWidthData = this.workorderViewTableColumnsData;
        break;
      case "Non-Dwelling-Report":
        this.columnWidthData = this.Non_Dwelling_ReportColumnsData;
        break;
      case "overLapReports":
        this.columnWidthData = this.overLapReports_ReportColumnsData;
        break;
      case "orderSummary":
        this.columnWidthData = this.orderSummaryTableColumnsData;
        break;

      case "repoOrderSummary":
        this.columnWidthData = this.repoOrderSummaryTableColumnsData;
        break;
      case "orderActivitySummary":
        this.columnWidthData = this.orderActivitySummaryTableColumnsData;
        break;
      case "invoiceSummary":
        this.columnWidthData = this.invoiceSummaryTableColumnsData;
        break;
      case "allTableheaders":
        this.columnWidthData = this.allTableColumnsData;
        break;

      case "approvalAllTableheaders":
        this.columnWidthData = this.approvalAllTableheadersColumnsData;
        break;

      case "selectedAllTableDataArrayHeaders":
        this.columnWidthData = this.selectedAllTableDataArrayHeadersColumnsData;
        break;

      case "vendorTableColumns":
        this.columnWidthData = this.vendorTableColumnsColumnsData;
        break;
      case "rateColumnsHeaders":
        this.columnWidthData = this.rateColumnsHeadersData;
        break;

      case "laneColumnsHeaders":
        this.columnWidthData = this.laneColumnsHeadersData;
        break;
      default:
        this.columnWidthData = this.MnrTableColumnsData;
        break;
    }
    newValue = data.map((item) => {
      // return { header: item, columnWidth: this.getWidth(item) ?? '180px' };
      return {
        field: item,
        header: item,
        columnWidth: this.getWidth(item) ?? "180px",
        align: this.getAlignment(item) ?? "center",
      };
    });
    return newValue;
  }

  getWidth(item) {
    try {
      var object = this.columnWidthData.find(function (value) {
        return value["header"] == item;
      });
      return object?.["columnWidth"];
    } catch (exception) {}
  }

  getAlignment(item) {
    try {
      var object = this.columnWidthData.find(function (value) {
        return value["header"] == item;
      });
      return object?.["align"];
    } catch (exception) {}
  }

  compareTwoArrays(
    tableInputHeaders,
    LocalstoredHeader,
    comparingKey,
    locastorageName
  ) {
    debugger;
    let newValuestableInputHeaders = [];
    let commonValuesInBothArrays = [];
    // * checking for common values in new headers passed to table and already saved localstorage header values
    commonValuesInBothArrays = LocalstoredHeader.filter((arrayOneEachItem) =>
      tableInputHeaders.some(
        (arrayTwoEachItem) =>
          arrayOneEachItem[comparingKey] == arrayTwoEachItem[comparingKey]
      )
    );

    // * checking for new values which are not in localstorage header values
    newValuestableInputHeaders = tableInputHeaders.filter(
      (arrayOneEachItem) =>
        !LocalstoredHeader.some(
          (arrayTwoEachItem) =>
            arrayOneEachItem[comparingKey] === arrayTwoEachItem[comparingKey]
        )
    );

    // * Adding new values and common values for updating in localstorage
    let finalArray = [
      ...commonValuesInBothArrays,
      ...newValuestableInputHeaders,
    ];
    localStorage.setItem(locastorageName, JSON.stringify(finalArray));
    return finalArray;
  }

  // * to set action coolumn widths dynamically
  getActionsColumnWith(...enabledIcons) {
    let iconsArray = [...enabledIcons];
    let actionsColumnwidth = 0;
    let truthyvalues = iconsArray.filter((item) => {
      if (item === true) return item;
    });
    if (truthyvalues?.length === 1) actionsColumnwidth = 80;
    if (truthyvalues?.length >= 2)
      actionsColumnwidth = truthyvalues?.length * 50;
    return actionsColumnwidth;
  }
}
