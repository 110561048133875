import { TabService } from "./services/tabmenu.service";
import { filter, timeout } from "rxjs/operators";
import { Component, OnInit, ɵConsole } from "@angular/core";
import { AppMainComponent } from "./app.main.component";
import { AuthService } from "./auth-guard/auth.service";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Tab } from "./tabmenu/tabmodel";
import * as CryptoJS from "crypto-js";
import { SharedDataService } from "./services/data/shared-data.service";
@Component({
  selector: "app-menu",
  templateUrl: "./app.menu.component.html",
})
export class AppMenuComponent implements OnInit {
  userdtl: any;

  model = [];
  model1: any[];
  imgurl = "";
  layoutConfig: string;
  menuType: boolean;
  model2: any;
  public encryptInfo;
  public decryptedInfo;
  storageData: any;
  // sharedDataService: any;
  constructor(
    public app: AppMainComponent,
    private authservice: AuthService,
    public tabService: TabService,
    public sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.userdtl = this.authservice.getstoragedtl();
    this.authservice.roleBased().then((data) => {
      // ! for production response data in encrypted string formate
      // console.log("rolesData", data);
      var Data = CryptoJS.AES.decrypt(
        decodeURIComponent(data),
        "Flexiv@n_C@ms2.0"
      );
      this.decryptedInfo = JSON.parse(Data.toString(CryptoJS.enc.Utf8));
      let rolesData = this.decryptedInfo?.roles?.data;

      //this.userdtl["user_role"] = "BI"  // for just testing
      //console.log('New Role :', this.userdtl["user_role"] );
      //  console.log('Roles :', rolesData );
      // -----local purpose--------
      // let rolesData = data;  // ! for local testing purposes only data in json formate
      var loadpage = "";
      if (rolesData[0][this.userdtl["user_role"]]) {
        this.model1 = rolesData[0][this.userdtl["user_role"]]["data"];
        // this.sharedDataService.userAccessableData = rolesData[0][this.userdtl["user_role"]]["data"];
        loadpage = rolesData[0][this.userdtl["user_role"]]["start_page"];
        this.allPagesObjects(rolesData[0][this.userdtl["user_role"]]["data"]);
      } else {
        this.authservice.showtoast(
          "error",
          "You have no access, Please contact admin"
        );
        setTimeout(() => {
          this.authservice.logout();
          return;
        }, 2000);
      }

      for (var z = 0; z < this.model1.length; z++) {
        if (this.model1[z].items) {
          for (var y = 0; y < this.model1[z].items.length; y++) {
            if (this.model1[z].items[y].items) {
              for (var x = 0; x < this.model1[z].items[y].items.length; x++) {
                if (this.model1[z].items[y].items[x].label == loadpage) {
                  //found
                  // this.startpage(this.model1[z].items[y].items[x]);
                }
              }
            }
            if (this.model1[z].items[y].label == loadpage) {
              //found
              // this.startpage(this.model1[z].items[y]);
            }
          }
        } else {
          if (this.model1[z].label == loadpage) {
            //found
            // this.startpage(this.model1[z]);
          }
        }
      }
      if (this.userdtl["user_id"] === "mnrdemo") {
        return (this.model = this.model1);
      } else {
        this.model = this.filterBasedOnRole();
        return this.model;
      }
    });

    // ! For hiding MNR  based on login type uncomment condition remove return statement in else block
    if (this.userdtl["user_id"] === "mnrdemo") {
      return (this.model = this.model1);
    } else {
      this.model = this.filterBasedOnRole();
      return this.model;
    }
  }

  filterBasedOnRole() {
    let moduleLevelFilter = [];
    moduleLevelFilter = this.model1;
    return moduleLevelFilter;
  }

  filterOptionsBasedOnRoles(param1, param2) {
    let filteredOtions = [];
    filteredOtions = this.model1.filter(
      (item) => item.label === param1 || item.label === param2
    );
    return filteredOtions;
  }

  allPagesObjects(data) {
    let totalItemsList = [];
    data.forEach((eachItem) => {
      eachItem?.items?.forEach((item) => {
        if (item?.items) {
          item.items.forEach((item) => {
            totalItemsList.push(item);
          });
        }
        totalItemsList.push(item);
      });
    });
    this.sharedDataService.userAccessableData = totalItemsList;
  }
}
