import { Injectable } from "@angular/core";
import { Tab } from "../tabmenu/tabmodel";
import { BehaviorSubject } from "rxjs";
@Injectable()
export class TabService {
  public tabs: Tab[] = [];

  public tabSub = new BehaviorSubject<Tab[]>(this.tabs);
  existedTabindex: any;

  constructor() {}
  public removeTab(index: number) {
    this.tabs.splice(index, 1);
    if (this.tabs.length > 0) {
      this.tabs[this.tabs.length - 1].active = true;
    }
    this.tabSub.next(this.tabs);
  }
  public addTab(tab: Tab) {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].active === true) {
        this.tabs[i].active = false;
      }
    }
    let existedTab = this.tabs.find((eachTab, index) => {
      if (eachTab.title === tab.title) {
        this.existedTabindex = index;
        return eachTab;
      }
    });
    if (existedTab) {
      existedTab.active = true;
      this.tabs[this.existedTabindex] = existedTab;
      this.tabSub.next(this.tabs);
    } else {
      tab.id = this.tabs.length + 1;
      tab.active = true;
      this.tabs.push(tab);
      this.tabSub.next(this.tabs);
    }
  }
  public emptytabs() {
    this.tabs = [];
    this.tabSub.next(this.tabs);
  }
}
