import {
    Component,
    Input,
    ComponentFactoryResolver,
    ViewChild,
    OnInit
    } from "@angular/core";
    import { ContentContainerDirective } from "./contentcontainer";
    import { Tab } from "./tabmodel";
    interface SkeletonComponent {
      pagedtl: any;
      }
      
    @Component({
    selector: "app-tab-content",
    template: "<div ><ng-template content-container></ng-template></div>",
 
    })
    export class TabContentComponent implements OnInit {
    @Input() tab;
    @ViewChild(ContentContainerDirective, { static: true })
    contentContainer: ContentContainerDirective;
    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
    ngOnInit() {
    const tab: Tab = this.tab;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(tab.component);
    const viewContainerRef = this.contentContainer.viewContainerRef;
    const componentRef = viewContainerRef.createComponent(componentFactory);
    (componentRef.instance as SkeletonComponent).pagedtl = tab.tabData;
    }}