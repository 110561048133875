import { Component, Input } from "@angular/core";
import { AppMainComponent } from "./app.main.component";
import { AuthService } from "./auth-guard/auth.service";

import { EnvconfigService } from "./envconfig.service";
import { TabService } from "./services/tabmenu.service";
@Component({
  selector: "app-topbar",
  template: `
    <div class="navigation">
      <img
        class="navigation__logo"
        src="assets/flexivanlogo_dark.png"
        alt="flexivan-logo"
      />
      <span class="navigation__contact-title"
        >{{ nav_first_msg }}
        <span style="color:red;"> {{ nav_middle_msg }}</span>
        {{ nav_last_msg }}</span
      >
      <!-- afds -->
      <div class="navigation__contact">
        <div class="navigation-section">
          <div class="signout-btn">
            <button *ngIf="username" (click)="signout()">Sign out</button>
          </div>
          <!-- <div>
        <div class="navigation__contact-content">
          <i class="fa-solid fa-phone navigation__contact-icon"></i>
          <span>000-000-0000</span>
        </div>
        <p class="navigation__contact-title">Proper Contact Information</p>
      </div> -->
        </div>
      </div>
    </div>
  `,
})
export class AppTopBarComponent {
  activeItem: number;
  username: string = "";
  userdtl: any;
  intermediateJson: any;
  nav_first_msg: any;
  nav_middle_msg: any;
  nav_last_msg: any;
  constructor(
    public tabService: TabService,
    public app: AppMainComponent,
    private authservice: AuthService,
    private API_content: EnvconfigService
  ) {
    this.username =
      this.authservice.getstoragedtl().first_name +
      " " +
      this.authservice.getstoragedtl().last_name;
    this.userdtl = this.authservice.getstoragedtl();
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.intermediateJson = this.API_content.getContent();
    this.nav_first_msg = this.intermediateJson.nav_first_msg;
    this.nav_middle_msg = this.intermediateJson.nav_middle_msg;
    this.nav_last_msg = this.intermediateJson.nav_last_msg;
  }
  signout() {
    this.username = "";
    this.authservice.logout();
  }
}
