import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, throwError } from "rxjs";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { MessageService } from "primeng/api";
import * as AWS from "aws-sdk";
import { EnvconfigService } from "../envconfig.service";
import { SharedDataService } from "../services/data/shared-data.service";
import { APIMSALConfigsService } from "../services/apiConfigs/api-msal-configs.service";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  rememberme: boolean;
  logindata: any;
  username: any;
  password: any;
  Alertmessage: any;
  baseurl: any;
  validate_obj: any = {
    taxamout: 9999,
    taxrate: 99,
    Quantity: 99,
    labour: 99,
    labourcost: 99999.99,
    labourate: 9999,
    hours: 99,
    partqty: 99,
    partcost: 9999.99,
    itemtotalcost: 99999.99,
    adminfee: 9999,
    amount: 9999,
    total: 999999,
  };
  showalert: boolean;

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    observe: "response" as "response",
  };
  loader: boolean = false;
  public loadersub = new BehaviorSubject<boolean>(this.loader);
  s3 = new AWS.S3({
    accessKeyId: "AKIA22DCUZYCLP7IC3OO",
    secretAccessKey: "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",
    region: "us-west-1",
  });
  constructor(
    private router: Router,
    private http: HttpClient,
    private messageService: MessageService,
    public environmentUrls: EnvconfigService,
    public SharedService: SharedDataService
  ) {
    // this.setEnvironmentPaths()
  }

  //  url      = "https://aimchassis.com/cams/";
  // ! ----live---------------
  //  url = "https://aimchassis.com/av/";
  //  imageurl = "https://aimchassis.com/";
  //  emailurl = "https://www.aimchassis.com/objs/";
  // ocbillingpdf_url = "https://www.aimchassis.com/objs/";
  //sendinoviceurl="https://www.aimchassis.com/aimview/objs/";
  // * --------test------------
  // url = this.SharedService.environmentURLs?.url;
  // imageurl = this.SharedService.environmentURLs?.imageurl;
  // emailurl = this.SharedService.environmentURLs?.emailurl;
  // ocbillingpdf_url = this.SharedService.environmentURLs?.ocbillingpdf_url;

  // invoice_OC_Pdf_Url = this.SharedService.environmentURLs?.invoice_OC_Pdf_Url;
  // invoice_Mc_Pdf_Url = this.SharedService.environmentURLs?.invoice_Mc_Pdf_Url;

  // imageurlPath = this.SharedService.environmentURLs?.imageurlPath;
  // schurl = this.SharedService.environmentURLs?.schurl;
  // sendinoviceurl=this.SharedService.environmentURLs?.sendinoviceurl;
  // url = "https://132.148.133.160:7000/";
  // imageurl = "https://132.148.133.160/";
  // emailurl = "https://132.148.133.160/aim-view/objs/";
  // ocbillingpdf_url = "https://132.148.133.160/objs/";
  // invoice_OC_Pdf_Url = "https://csmapp.flexivan.com/UPCInvoices/";
  // invoice_Mc_Pdf_Url = "https://csmapp.flexivan.com/CSMCustomerInvoices/";
  // imageurlPath = "https://132.148.133.160/admin/";
  // schurl = "https://132.148.133.160:3000/";

  // url = "https://132.148.133.160:7000/";
  // imageurl = "https://132.148.133.160/";
  // emailurl = "https://132.148.133.160/aim-view/objs/";
  // ocbillingpdf_url = "https://132.148.133.160/objs/";
  // invoice_OC_Pdf_Url = "https://csmapp.flexivan.com/UPCInvoices/";
  // invoice_Mc_Pdf_Url = "https://csmapp.flexivan.com/CSMCustomerInvoices/";
  // imageurlPath = "https://132.148.133.160/admin/";
  // schurl = "https://132.148.133.160:3000/";
  // sendinoviceurl="https://132.148.133.160/aim-view/objs/";
  // ! ----QA URLs---------------
  //  https://camsdev.flexi-van.com/
  //  url = "https://camsdev.flexi-van.com/av/";
  //  imageurl = "https://camsdev.flexi-van.com/";
  //  emailurl = "https://www.camsdev.flexi-van.com/objs/";
  // ocbillingpdf_url = "https://www.camsdev.flexi-van.com/objs/";

  getbaseurl() {
    return this.SharedService.environmentURLs?.API_URL;
  }
  getQlikTicketurl() {
    return this.SharedService.environmentURLs?.qliktokenurl;
  }

  getImageUrl() {
    return this.SharedService.environmentURLs?.imageurl;
  }
  getEmailUrl() {
    return this.SharedService.environmentURLs?.emailurl;
  }
  getocbillingpdf_url() {
    return this.SharedService.environmentURLs?.ocbillingpdf_url;
  }
  getinvoice_OC_Pdf_Urll() {
    return this.SharedService.environmentURLs?.invoice_OC_Pdf_Url;
  }
  getinvoice_Mc_Pdf_Url() {
    return this.SharedService.environmentURLs?.invoice_Mc_Pdf_Url;
  }
  getschurl() {
    return this.SharedService.environmentURLs?.schurl;
  }
  getsendinoviceurl() {
    return this.SharedService.environmentURLs?.sendinoviceurl;
  }
  azureLoginDetails() {
    return this.SharedService.environmentURLs;
  }

  // getschurl() {
  //   return this.schurl;
  // }
  selectdata(urlmethod, dataparam) {
    dataparam.x_access_token = window.sessionStorage.getItem("jwttoken");
    if (urlmethod == "loginchecking") {
      return this.http.post(
        this.getbaseurl() + urlmethod,
        dataparam,
        this.httpOptions
      );
    } else {
      return this.http.post(this.getbaseurl() + urlmethod, dataparam);
    }
  }

  get_Ticket(urlmethod, dataparam) {
    dataparam.x_access_token = window.sessionStorage.getItem("jwttoken");
    return this.http.post(this.getQlikTicketurl() + urlmethod, dataparam);
  }

  selectgetdata(urlmethod) {
    return this.http.get(this.getbaseurl() + urlmethod);
  }

  emailsending(urlmethod, dataparam) {
    return this.http.get(this.getEmailUrl() + urlmethod, dataparam);
  }

  sendemailurlresp(urlmethod, parameter, reponse) {
    return this.http.get(this.getEmailUrl() + urlmethod + parameter, {
      responseType: reponse,
    });
  }
  // ocpdfurlresp(urlmethod, parameter, reponse) {
  //   return this.http.get(this.getocbillingpdf_url() + urlmethod + parameter, {
  //     responseType: reponse,
  //   });
  // }

  ocpdfurlresp(urlmethod, parameter) {
    return this.http.post(this.getocbillingpdf_url() + urlmethod, parameter);
  }
  // ocpdfurlresp(urlmethod, parameter) {
  //   return this.http.post(this.ocbillingpdf_url+ urlmethod, parameter);

  // }
  IUDdata(urlmethod, dataparam, reponse) {
    dataparam.x_access_token = window.sessionStorage.getItem("jwttoken");
    return this.http.post(this.getbaseurl() + urlmethod, dataparam, {
      responseType: reponse,
    });
  }
  Sch_IUDdata(urlmethod, dataparam, reponse) {
    dataparam.x_access_token = window.sessionStorage.getItem("jwttoken");
    return this.http.post(this.getbaseurl() + urlmethod, dataparam, {
      responseType: reponse,
    });
  }

  showCloseButton: true;

  login(
    first_name: string,
    password_txt: string,
    loginType = "normal",
    idToken = "",
    msToken = ""
  ) {
    let link = "";
    let data = null;
    if (loginType == "office365") {
      // this.SharedService.enableLoginLoaderState(true)
      link = APIMSALConfigsService.getConfig()?.API_URL + "userlogindetails";
      data = {
        first_name: first_name,
        x_access_token: idToken,
        mstoken: msToken,
      };
    } else {
      link = APIMSALConfigsService.getConfig()?.API_URL + "loginchecking";
      data = {
        first_name: first_name,
        password_txt: password_txt,
      };
    }

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      observe: "response" as "response",
    };
    // this.SharedService.enableLoginLoaderState(true)
    this.http.post<any>(link, data, httpOptions).subscribe((resp: any) => {
      var header = resp.headers.get("x-access-token");
      window.sessionStorage.setItem("jwttoken", header);
      if (
        resp.body != null &&
        resp.body.length > 0 &&
        resp.body[0].aim360_status == "N"
      ) {
        this.SharedService.enableLoginLoaderState(false);
        // !  && ( (['SUP','POOL']).indexOf(resp.body[0].user_role ) > -1)
        // if(resp.body[0].user_status=='A' && ( (['SUP','POOL']).indexOf(resp.body[0].user_role ) > -1))
        if (resp.body[0].user_status == "A") {
          window.sessionStorage.setItem(
            "userdata",
            JSON.stringify(resp.body[0])
          );
          window.sessionStorage.setItem("userid", resp.body[0].user_id);
          window.sessionStorage.setItem("username", resp.body[0].first_name);

          this.router.navigate(["/home"]);
          localStorage.setItem("isFirstReload", JSON.stringify("yes"));
        } else if (resp.body[0].user_status == "V") {
          this.showtoast("warn", "Your Account Is Not Activate");
        } else if (resp.body[0].user_status == "P") {
          this.showtoast("warn", "Your Account Is Not Verified");
        } else if (resp.body[0].user_status == "S") {
          this.showtoast("warn", "Your Account Is Suspended");
        }
      } else {
        this.SharedService.enableLoginLoaderState(false);
        loginType == "office365"
          ? this.showtoast("error", "You have no access,Please Contact admin")
          : this.showtoast("error", "Invalid Email Or Password");
      }
    });
  }

  logout() {
    this.router.navigate(["/logout"]);
    window.sessionStorage.clear();
    window.sessionStorage.setItem("jwttoken", "");
    window.sessionStorage.setItem("fromLogoutclick", "true");
  }

  getstoragedtl() {
    return JSON.parse(window.sessionStorage.getItem("userdata"));
  }

  resetstoragevalues(columntxt, colval) {
    var locdata = this.getstoragedtl();
    locdata[columntxt] = colval;
    window.sessionStorage.setItem("userdata", JSON.stringify(locdata));
  }
  localstorageupdate(key, value) {
    localStorage.setItem(key, value);
  }
  getlocalstoragevalues(key) {
    return localStorage.getItem(key);
  }

  generatePIN(digits) {
    var i = 0; //counter
    var pin = ""; //our default pin is blank.
    while (i < digits) {
      //generate a random number between 0 and 9.
      pin += Math.floor(Math.random() * (9 - 0) + 0);
      i++;
    }
    return pin;
  }

  getaccesiblestring(permtxt) {
    if (permtxt.length > 0) {
      permtxt = permtxt.split("|");
      return {
        btnsave: permtxt[0],
        btnupdate: permtxt[1],
        btndelete: permtxt[2],
        btnview: permtxt[3],
        btnprint: permtxt[4],
        btnexport: permtxt[5],
      };
    }
  }
  getemailobjectstring(dataobj) {
    var datatxt = "";
    for (var key in dataobj) {
      datatxt += key + "=" + dataobj[key] + "&";
    }
    return datatxt.slice(0, -1);
  }

  showtoast(type: string, msg: string) {
    if (type == "success") {
      this.messageService.add({
        severity: "success",
        detail: msg,
        closable: true,
      });
    } else if (type == "error") {
      this.messageService.add({
        severity: "error",
        detail: msg,
        closable: true,
      });
    } else if (type == "warn") {
      this.messageService.add({
        severity: "warn",
        detail: msg,
        closable: true,
      });
    }
  }
  JSONdatareader(filepath) {
    return this.http
      .get<any>(filepath)
      .toPromise()
      .then((res) => res.data as any[])
      .then((data) => data);
  }

  ImageHttpOptions = {
    headers: new HttpHeaders({}),
    observe: "response" as "response",
    responseType: "text" as "json",
  };
  ImageHttpOptionsTwo = {
    headers: new HttpHeaders({}),
    observe: "response" as "response",
    responseType: "text" as "json",
  };

  sendImgFormData(urlmethod, formData) {
    // for file uploadings
    formData.append(
      "x_access_token",
      window.sessionStorage.getItem("jwttoken")
    );
    return this.http.post(
      this.getImageUrl() + urlmethod,
      formData,
      this.ImageHttpOptions
    );
  }

  async imageUploadsynchronousAPI(urlmethod, formData) {
    return await this.http
      .post<string>(
        this.getImageUrl() + urlmethod,
        formData,
        this.ImageHttpOptionsTwo
      )
      .toPromise();
  }

  deleteImage(urlmethod, imageFilePath) {
    return this.http.post(
      this.getImageUrl() + urlmethod + "delete_file=" + imageFilePath.trim(),
      {},
      { headers: new HttpHeaders({ "Content-Type": "application/json" }) }
    );
  }

  async synchronousApiCall(urlmethod, dataparam) {
    dataparam.x_access_token = window.sessionStorage.getItem("jwttoken");
    return await this.http
      .post(this.getbaseurl() + urlmethod, dataparam)
      .toPromise();
  }
  async expiryTime(filepath) {
    return await this.http.get<any>(filepath).toPromise();
  }

  // ! for local testing purposes only data in json formate
  // roleBased() {
  //   return this.http.get<any>('assets/demo/data/RoleBasedAccessData(important).json')
  //       .toPromise()
  //       .then(res => res.data)
  //       .then(data => data);
  // }

  // ! for production response data in encrypted string formate
  roleBased() {
    return this.http
      .get<any>("assets/demo/data/RoleBasedAccess.json")
      .toPromise()
      .then((res) => res.data)
      .then((data) => data);
  }

  get_aws_signedurl(key, file_name, bucketname) {
    var s3url = this.s3.getSignedUrl("getObject", {
      Bucket: bucketname,
      //  Key:'Billing/CustomerInvoices/'+file_name
      Key: key + file_name,
    });

    return s3url;
  }

  checkclickableIndexNumbers(data, serachingColoumnKeyNameArray) {
    let index = [];
    if (data) {
      serachingColoumnKeyNameArray.forEach((element) => {
        index.push(data?.findIndex((x) => x.header === element));
      });
    }
    return index;
  }

  getClickableRowNames(indexnumbers, arrayOfKeyValues, required = "values") {
    let values = [];
    arrayOfKeyValues?.forEach((item, i) => {
      if (indexnumbers.includes(item.header)) {
        if (required === "values") {
          values.push(item);
        } else {
          values.push(i);
        }
      }
    });
    return values;
  }
  // sendMail(urlmethod) {
  //   return this.http.get(this.getsendinoviceurl() + urlmethod);
  // }
  sendMail(urlmethod, parameter) {
    return this.http.post(urlmethod, parameter);
  }
  workorderpdfresp(urlmethod, parameter) {
    return this.http.post(urlmethod, parameter);
  }
  deleteWOPdf(urlmethod, imageFilePath) {
    return this.http.post(
      urlmethod + "delete_file=" + imageFilePath.trim(),
      {},
      { headers: new HttpHeaders({ "Content-Type": "application/json" }) }
    );
  }

  deleteImagePath(data) {
    this.selectdata("wo_bwoq_so_images_delete", {
      delete_data: data,
    }).subscribe((response) => {
      if (response[0]) {
        console.log(response["result_cd"]);
      }
      return response;
    });
  }

  async readmskeys() {
    return await this.http
      .get("https://login.microsoftonline.com/common/discovery/keys")
      .toPromise();
  }
}
