import { Component } from "@angular/core";
import { AuthService } from "./auth-guard/auth.service";
import { Router } from "@angular/router";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import * as CryptoJS from "crypto-js";
import { SharedDataService } from "./services/data/shared-data.service";
import { EnvconfigService } from "./envconfig.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  expiryTime: number;
  timedOut: boolean;
  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private authservice: AuthService,
    private router: Router,
    private shareData: SharedDataService,
    public environmentUrls: EnvconfigService
  ) {
    this.shareData.setAppEnvronmentalURLconfigData(
      this.environmentUrls.getContent()
    );
    this.shareData.environmentUrls = this.environmentUrls.getContent();
    // this.expiryTimeNumber();
    // this.getTablecolumnData(); // ! enable for table header data for testing in local (reads json formate file )
    // this.getEncryptedTablecolumnData(); // ! enable for table header data (reads encrypted formate file )
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.watch();
    idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.router.url !== "/" ? this.authservice.logout() : null;
      this.reset();
    });
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  // expiryTimeNumber() {
  //   this.authservice
  //     .expiryTime("assets/demo/data/appconfig.json")
  //     .then((data) => {
  //       var Data = CryptoJS.AES.decrypt(
  //         decodeURIComponent(data?.data),
  //         "Flexiv@n_C@ms2.0"
  //       );
  //       let decryptedInfo = JSON.parse(Data.toString(CryptoJS.enc.Utf8));
  //       let configData = decryptedInfo?.["data"];
  //       this.shareData.setAppconfigData(configData);
  //       this.shareData.costRelated_keyNames = configData;
  //       this.shareData.DateRelated_keyNames = configData;
  //       this.shareData.numberFormate_keyNames = configData;
  //       this.expiryTime = configData?.["data"]?.[0].tockenExpiryTime;
  //       this.idle.setIdle(this.expiryTime);
  //       this.idle.setTimeout(1);
  //       return this.expiryTime;

  //       // for json file read
  //       //   this.expiryTime = data.data[0].tockenExpiryTime;
  //       //   this.shareData.setAppconfigData(data);
  //       //   this.shareData.costRelated_keyNames = data
  //       //   this.shareData.DateRelated_keyNames = data
  //       //   console.log('',`${this.shareData.costRelated_keyNames}`)
  //       //   console.log('',`${this.shareData.DateRelated_keyNames}`)
  //       //   this.idle.setIdle(this.expiryTime);
  //       //   this.idle.setTimeout(1);
  //       //  return this.expiryTime
  //     });
  // }

  // ! table header data for testing in local (reads json formate file )
  // getTablecolumnData() {
  //   this.authservice
  //     .expiryTime("assets/demo/data/tablecolumnsdata(important).json")
  //     .then((data) => {
  //       this.shareData.settableConfigData(data);
  //       this.shareData.tableCoulumData = data;
  //     });
  // }

  // ! table header data for testing in local (reads encrypted formate file )
  // getEncryptedTablecolumnData() {
  //   this.authservice
  //     .expiryTime("assets/demo/data/tablecolumnsdata.json")
  //     .then((data) => {
  //       var Data = CryptoJS.AES.decrypt(
  //         decodeURIComponent(data?.data),
  //         "Flexiv@n_C@ms2.0"
  //       );
  //       let decryptedInfo = JSON.parse(Data.toString(CryptoJS.enc.Utf8));
  //       this.shareData.settableConfigData(decryptedInfo?.data);
  //       this.shareData.tableCoulumData = decryptedInfo?.data;
  //       return this.expiryTime;
  //     });
  // }
}
