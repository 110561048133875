import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  DecimalPipe,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";

// PrimeNG Components for demos
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipsModule } from "primeng/chips";
import { CodeHighlighterModule } from "primeng/codehighlighter";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ColorPickerModule } from "primeng/colorpicker";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { FullCalendarModule } from "primeng/fullcalendar";
import { GalleriaModule } from "primeng/galleria";
import { InplaceModule } from "primeng/inplace";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { LightboxModule } from "primeng/lightbox";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SlideMenuModule } from "primeng/slidemenu";
import { SliderModule } from "primeng/slider";
import { SpinnerModule } from "primeng/spinner";
import { SplitButtonModule } from "primeng/splitbutton";
import { StepsModule } from "primeng/steps";

import { TabMenuModule } from "primeng/tabmenu";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { TerminalModule } from "primeng/terminal";
import { TieredMenuModule } from "primeng/tieredmenu";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeTableModule } from "primeng/treetable";
import { VirtualScrollerModule } from "primeng/virtualscroller";

// Application Components
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppMainComponent } from "./app.main.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppLoginComponent } from "./pages/app.login.component";
// import { AppCrudComponent } from './pages/app.crud.component';
import { AppMenuComponent } from "./app.menu.component";
import { AppConfigComponent } from "./app.config.component";
import { AppTopBarComponent } from "./app.topbar.component";
import { AppFooterComponent } from "./app.footer.component";
import { Tabsmenu } from "./tabmenu/tabmenu.component";
import { TabContentComponent } from "./tabmenu/tabcontent";

import { ContentContainerDirective } from "./tabmenu/contentcontainer";
import { ConfirmationService, MessageService } from "primeng/api";
import { KeyFilterModule } from "primeng/keyfilter";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { HttpErrorInterceptor } from "./error.interceptor";

import {
  MsalService,
  MSAL_INSTANCE,
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
} from "./msal";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from "@azure/msal-browser";
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from "./msal/constants";
import { MsalGuardConfiguration } from "./msal/msal.guard.config";
import { MsalInterceptorConfig } from "./msal/msal.interceptor.config";

import * as auth from "./auth-config.json";
import * as office365 from "../assets/demo/data/office365-config.json";

// import * as auth from '../assets/demo/data/environmentConfig.json';
import * as CryptoJS from "crypto-js";
import { EnvconfigService } from "./envconfig.service";
// import * as auth from './auth-config.json';
import { APIMSALConfigsService } from "./services/apiConfigs/api-msal-configs.service";
import { LogoutComponent } from "./logout/logout.component";
import { TabService } from "./services/tabmenu.service";
// import { OnlyNumberDirective } from "./sharedmodule/directives/onlynumbers/only-number.directive";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {}

export function MSALInstanceFactory(): IPublicClientApplication {
  // var Data = CryptoJS.AES.decrypt(
  //   decodeURIComponent(auth?.data),
  //   "Flexiv@n_C@ms2.0"
  // );
  // let decryptedInfo = JSON.parse(Data.toString(CryptoJS.enc.Utf8));
  // let configData = decryptedInfo?.["data"];
  return new PublicClientApplication({
    // auth: {
    //   clientId: configData.credentials.clientId,
    //   authority:
    //     "https://login.microsoftonline.com/" + configData.credentials.tenantId,
    //   redirectUri: configData.configuration.redirectUri,
    //   postLogoutRedirectUri: configData.configuration.postLogoutRedirectUri,
    // },
    auth: {
      clientId: APIMSALConfigsService.getConfig()?.credentials.clientId,
      // clientId: '2e8471b3-b746-4986-9142-da2bac926aeb',
      authority:
        "https://login.microsoftonline.com/" +
        APIMSALConfigsService.getConfig()?.credentials.tenantId,
      redirectUri: APIMSALConfigsService.getConfig()?.configuration.redirectUri,
      postLogoutRedirectUri:
        APIMSALConfigsService.getConfig()?.configuration.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", [
    "user.read",
    "openid",
    "profile",
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Popup };
}

// const appConfig = (config: EnvconfigService) => {
//   return () => {
//     return config.loadConfig();
//   };
// };
const appContentConfig = (config: EnvconfigService) => {
  return () => {
    return config.loadContent();
  };
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DropdownModule,

    FileUploadModule,
    FullCalendarModule,
    GalleriaModule,
    InplaceModule,
    InputMaskModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    SelectButtonModule,
    SidebarModule,
    SlideMenuModule,
    SliderModule,
    SpinnerModule,
    SplitButtonModule,
    StepsModule,
    FieldsetModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
    ChipsModule,
    KeyFilterModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    AppMainComponent,
    AppMenuComponent,
    AppTopBarComponent,
    AppFooterComponent,
    Tabsmenu,
    TabContentComponent,
    ContentContainerDirective,
    AppConfigComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppLoginComponent,
    // AppCrudComponent,
    // DashboardDemoComponent,
    // FormLayoutDemoComponent,
    // InputDemoComponent,
    // ButtonDemoComponent,
    // TableDemoComponent,
    // ListDemoComponent,
    // TreeDemoComponent,
    // PanelsDemoComponent,
    // OverlaysDemoComponent,
    // MediaDemoComponent,
    // MenusDemoComponent,
    // MessagesDemoComponent,
    // MessagesDemoComponent,
    // MiscDemoComponent,
    // ChartsDemoComponent,
    // EmptyDemoComponent,
    // FileDemoComponent,
    // DocumentationComponent,
    // DisplayComponent,
    // ElevationComponent,
    // FlexboxComponent,
    // GridComponent,
    // IconsComponent,
    // SpacingComponent,
    // TypographyComponent,
    // TextComponent,
    // WidgetsComponent,
    LogoutComponent,
    // TableComponent,
    // TwoDigitsAfterDecimalsDirective
    // OnlyNumberDirective
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    EnvconfigService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appConfig,
    //   multi: true,
    //   deps: [EnvconfigService],
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: appContentConfig,
      multi: true,
      deps: [EnvconfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    // {
    //   provide: MSAL_GUARD_CONFIG,
    //   useValue: {
    //     interactionType: InteractionType.Redirect
    //   } as MsalGuardConfiguration
    // },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MessageService,
    ConfirmationService,
    DecimalPipe,
    TabService,
  ],
  bootstrap: [AppComponent],
  exports: [
    AppTopBarComponent,
    AppFooterComponent,
    Tabsmenu,
    TabContentComponent,
    AppConfigComponent,
    AppMenuComponent,
    MessageModule,
  ],
})
export class AppModule {}
