import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { AppMainComponent } from "./app.main.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppLoginComponent } from "./pages/app.login.component";

import { AuthGuard } from "./auth-guard/auth.guard";
import { CanDeactivateGuard } from "./auth-guard/deactivateguard";
import { LogoutComponent } from "./logout/logout.component";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: "", component: AppLoginComponent },
        {
          path: "home",
          component: AppMainComponent,
          canActivate: [AuthGuard],
          canDeactivate: [CanDeactivateGuard],
          children: [],
        },

        { path: "error", component: AppErrorComponent },
        { path: "notfound", component: AppNotfoundComponent },
        { path: "login", component: AppLoginComponent },
        { path: "logout", component: LogoutComponent },

        // { path: "**", redirectTo: "/notfound" },
      ],
      { scrollPositionRestoration: "enabled", relativeLinkResolution: "legacy" }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
