import { Component, OnInit } from "@angular/core";
import { AppMainComponent } from "../app.main.component";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.css"],
})
export class LogoutComponent implements OnInit {
  src: string = "assets/flexivanlogo_dark.png";
  theme: any;

  constructor() {}

  ngOnInit(): void {
    debugger;
    if (localStorage.getItem("layoutConfig")) {
      this.theme = JSON.parse(
        localStorage.getItem("layoutConfig")
      )[0].configs.MenuColorsMode;

      if (this.theme == "dark" || this.theme === undefined) {
        this.src = "assets/layout/images/flexivanlogo_dark.png";
      } else {
        this.src = "assets/layout/images/flexivanlogo_light.png";
      }
      console.log(this.src, "image path");
    }
  }

  go_to_login() {
    debugger;
    window.open("http://apps.flexivan.com/", "_self");
  }
}
