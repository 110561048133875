import { Component, OnInit } from "@angular/core";
import { TabService } from '../services/tabmenu.service';
import { Tab } from './tabmodel';

@Component({
selector: "tabsmenu",
templateUrl: "./tabmenu.component.html",
})
export class Tabsmenu implements OnInit {
tabs = new Array<Tab>();
selectedTab: number;
    index: number;
constructor(private tabService: TabService) {
   
}
ngOnInit() {
    this.tabService.tabSub.subscribe(tabs => {
        setTimeout(()=>{
            this.tabs = tabs;
            this.index = tabs.findIndex(tab => tab.active);
            this.selectedTab = this.index;
            if(this.index == -1)
            {
                this.index = 0;
                this.selectedTab = 0;
            }
        },10);
        
        });
}
tabChanged(event) {
}

tabclose(event)
{
    this.tabService.removeTab(event.index);
}
}