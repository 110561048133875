import { Component, OnInit } from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { AppMainComponent } from "./app.main.component";
@Component({
  selector: "app-config",
  template: `
    <!-- <div
      id="layout-config"
      class="layout-config"
      (click)="app.onConfigClick($event)"
    >
      <a
        style="cursor: pointer"
        class="layout-config-close"
        (click)="closesettings()"
      >
        <i class="pi pi-times"></i>
      </a>
      <div class="layout-config-content">
        <h5 style="margin-top: 0">Input Style</h5>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              inputId="input_outlined"
              name="inputstyle"
              [(ngModel)]="app.inputStyle"
              value="outlined"
              (click)="_onInputStyleChange()"
            ></p-radioButton>
            <label for="input_outlined">Outlined</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              inputId="input_filled"
              name="inputstyle"
              [(ngModel)]="app.inputStyle"
              value="filled"
              (click)="_onInputStyleChange()"
            ></p-radioButton>
            <label for="input_filled">Filled</label>
          </div>
        </div>

        <h5>Ripple Effect</h5>
        <p-inputSwitch
          [ngModel]="app.ripple"
          (onChange)="app.onRippleChange($event); _onCHangeRippleEffect()"
        ></p-inputSwitch>

        <h5>Menu Type</h5>
        <div class="p-grid">
          <div class="p-col-6">
            <div class="p-field-radiobutton">
              <p-radioButton
                inputId="overlay"
                name="layoutMode"
                [(ngModel)]="app.horizontalMenu"
                (click)="_onMenuTypeChange()"
              ></p-radioButton>
              <label for="static">Overlay</label>
            </div>
          </div>
          <div class="p-col-6">
            <div class="p-field-radiobutton">
              <p-radioButton
                inputId="horizontal"
                name="layoutMode"
                [value]="true"
                [(ngModel)]="app.horizontalMenu"
                (click)="_onMenuTypeChange()"
              ></p-radioButton>
              <label for="horizontal">Horizontal</label>
            </div>
          </div>
        </div>

        <h5>Color Scheme</h5>
        <div class="p-grid">
          <div class="p-col-6">
            <div class="p-field-radiobutton">
              <p-radioButton
                inputId="light"
                name="colorScheme"
                [value]="false"
                [(ngModel)]="app.darkMode"
                (onClick)="changeLayout($event, false)"
                (ngModelChange)="dataChanged($event, 'true')"
              ></p-radioButton>
              <label for="light">Light</label>
            </div>
          </div>
          <div class="p-col-6">
            <div class="p-field-radiobutton">
              <p-radioButton
                inputId="dark"
                name="colorScheme"
                [value]="true"
                [(ngModel)]="app.darkMode"
                (onClick)="changeLayout($event, true)"
                (ngModelChange)="dataChanged($event, 'true')"
              ></p-radioButton>
              <label for="dark">Dark</label>
            </div>
          </div>
        </div>

        <h5>Menu Colors</h5>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              inputId="lightMenu"
              name="menuColor"
              value="light"
              [ngModel]="app.menuColorMode"
              (onClick)="changeMenuColorMode($event, 'light')"
            ></p-radioButton>
            <label for="lightMenu">Light</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              inputId="darkMenu"
              name="menuColor"
              value="dark"
              [ngModel]="app.menuColorMode"
              (onClick)="changeMenuColorMode($event, 'dark')"
            ></p-radioButton>
            <label for="darkMenu">Dark</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              inputId="customMenu"
              name="menuColor"
              value="custom"
              [ngModel]="app.menuColorMode"
              (onClick)="changeMenuColorMode($event, 'custom')"
            ></p-radioButton>
            <label for="customMenu">Custom</label>
          </div>
        </div>
        <div class="layout-themes">
          <div *ngFor="let color of menuColors">
            <a
              style="cursor: pointer"
              (click)="changeMenuColor($event, color.file,true)"
              [ngStyle]="{ 'background-color': color.color }"
            >
              <img
                src="assets/layout/images/configurator/menu/theme/{{
                  color.image
                }}"
                *ngIf="app.menuColorMode === 'custom'"
                style="height: 100%; width: 100%;"
                alt="{{ color.name }}"
              />
              <i class="pi pi-check" *ngIf="app.layoutColor === color.file"></i>
            </a>
          </div>
        </div>

        <h5>Themes</h5>
        <div class="layout-themes">
          <div *ngFor="let t of componentThemes">
            <a
              style="cursor: pointer"
              (click)="changeComponentTheme($event, t.file,true)"
              [ngStyle]="{ 'background-color': t.color }"
            >
              <i class="pi pi-check" *ngIf="app.themeColor === t.file"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  
    <div id="layout-config-updates"
    class="layout-config">
    <h4>Updates</h4>
    <a
    style="cursor: pointer"
    class="layout-config-close"
    (click)="closeUpdates()"
  >
    <i class="pi pi-times"></i>
  </a>
    </div> -->
  `,
  animations: [
    trigger("children", [
      state(
        "hiddenAnimated",
        style({
          opacity: 0,
          transform: " translateX(-50%) translateY(-50%)",
        })
      ),
      state(
        "visibleAnimated",
        style({
          opacity: 1,
          transform: "translateX(-50%) translateY(-50%) scale(1)",
        })
      ),
      transition(
        "visibleAnimated => hiddenAnimated",
        animate("150ms cubic-bezier(0, 0, 0.2, 1)")
      ),
      transition(
        "hiddenAnimated => visibleAnimated",
        animate("150ms cubic-bezier(0, 0, 0.2, 1)")
      ),
    ]),
  ],
})
export class AppConfigComponent implements OnInit {
  darkColors: any;

  lightColors: any;

  customColors: any;

  menuColors: any;

  componentThemes: any;
  userConfigs = [];
  reload: boolean = false;
  reloadPage: boolean = false;
  sidePannelColors: boolean;
  constructor(public app: AppMainComponent) {}

  ngOnInit() {
    this.lightColors = [
      { name: "Blue", file: "blue", color: "#5e81ac" },
      { name: "Green", file: "green", color: "#A3BE8C" },
      { name: "Yellow", file: "yellow", color: "#EBCB8B" },
      { name: "Cyan", file: "cyan", color: "#88C0D0" },
      { name: "Purple", file: "purple", color: "#B48EAD" },
      { name: "Orange", file: "orange", color: "#D08770" },
      { name: "Teal", file: "teal", color: "#88D0BD" },
      { name: "Magenta", file: "magenta", color: "#BD69AE" },
      { name: "Lime", file: "lime", color: "#B9BE7F" },
      { name: "Brown", file: "brown", color: "#BE9B7F" },
      { name: "Red", file: "red", color: "#F28686" },
      { name: "Indigo", file: "indigo", color: "#8886F2" },
    ];

    this.darkColors = [
      { name: "Blue", file: "blue", color: "#5e81ac" },
      { name: "Green", file: "green", color: "#A3BE8C" },
      { name: "Yellow", file: "yellow", color: "#EBCB8B" },
      { name: "Cyan", file: "cyan", color: "#88C0D0" },
      { name: "Purple", file: "purple", color: "#B48EAD" },
      { name: "Orange", file: "orange", color: "#D08770" },
      { name: "Teal", file: "teal", color: "#88D0BD" },
      { name: "Magenta", file: "magenta", color: "#BD69AE" },
      { name: "Lime", file: "lime", color: "#B9BE7F" },
      { name: "Brown", file: "brown", color: "#BE9B7F" },
      { name: "Red", file: "red", color: "#F28686" },
      { name: "Indigo", file: "indigo", color: "#8886F2" },
    ];

    this.customColors = [
      { name: "Chile", file: "chile", image: "chile.png" },
      { name: "Naples", file: "naples", image: "naples.png" },
      { name: "Georgia", file: "georgia", image: "georgia.png" },
      { name: "Infinity", file: "infinity", image: "infinity.png" },
      { name: "Chicago", file: "chicago", image: "chicago.png" },
      { name: "Majesty", file: "majesty", image: "majesty.png" },
      { name: "Fish", file: "fish", image: "fish.png" },
      { name: "Dawn", file: "dawn", image: "dawn.png" },
      { name: "Record", file: "record", image: "record.png" },
      { name: "Pool", file: "pool", image: "pool.png" },
      { name: "Metal", file: "metal", image: "metal.png" },
      { name: "China", file: "china", image: "china.png" },
      { name: "Table", file: "table", image: "table.png" },
      { name: "Panorama", file: "panorama", image: "panorama.png" },
      { name: "Barcelona", file: "barcelona", image: "barcelona.png" },
      { name: "Underwater", file: "underwater", image: "underwater.png" },
      { name: "Symmetry", file: "symmetry", image: "symmetry.png" },
      { name: "Rain", file: "rain", image: "rain.png" },
      { name: "Utah", file: "utah", image: "utah.png" },
      { name: "Wave", file: "wave", image: "wave.png" },
      { name: "Flora", file: "flora", image: "flora.png" },
      { name: "Speed", file: "speed", image: "speed.png" },
      { name: "Canopy", file: "canopy", image: "canopy.png" },
      { name: "SanPaolo", file: "sanpaolo", image: "sanpaolo.png" },
      { name: "Basketball", file: "basketball", image: "basketball.png" },
      { name: "Misty", file: "misty", image: "misty.png" },
      { name: "Summit", file: "summit", image: "summit.png" },
      { name: "Wall", file: "wall", image: "wall.png" },
      { name: "Ferris", file: "ferris", image: "ferris.png" },
      { name: "Ship", file: "ship", image: "ship.png" },
      { name: "NY", file: "ny", image: "ny.png" },
      { name: "Cyan", file: "cyan", image: "cyan.png" },
      { name: "Violet", file: "violet", image: "violet.png" },
      { name: "Red", file: "red", image: "red.png" },
      { name: "Blue", file: "blue", image: "blue.png" },
      { name: "Porsuk", file: "porsuk", image: "porsuk.png" },
      { name: "Pink", file: "pink", image: "pink.png" },
      { name: "Purple", file: "purple", image: "purple.png" },
      { name: "Orange", file: "orange", image: "orange.png" },
    ];

    this.menuColors = this.lightColors;

    this.componentThemes = [
      { name: "Blue", file: "blue", color: "#5E81AC" },
      { name: "Green", file: "green", color: "#99CE6B" },
      { name: "Yellow", file: "yellow", color: "#EBCB8B" },
      { name: "Cyan", file: "cyan", color: "#88C0D0" },
      { name: "Purple", file: "purple", color: "#B48EAD" },
      { name: "Orange", file: "orange", color: "#D08770" },
      { name: "Teal", file: "teal", color: "#88D0BD" },
      { name: "Magenta", file: "magenta", color: "#BD69AE" },
      { name: "Lime", file: "lime", color: "#B9BE7F" },
      { name: "Brown", file: "brown", color: "#BE9B7F" },
      { name: "Red", file: "red", color: "#F28686" },
      { name: "Indigo", file: "indigo", color: "#8886F2" },
    ];
    // setTimeout(() => {
    let existedConfig = JSON.parse(localStorage.getItem("layoutConfig"));

    if (Array.isArray(existedConfig) && existedConfig != null) {
      this.userConfigs = [];
      for (let i = 0; i < existedConfig.length; i++) {
        this.userConfigs.push(existedConfig[i]);
      }

      let isExist = existedConfig.some((element) => {
        return element.userName === window.sessionStorage.getItem("userid");
      });
      // if (isExist) {
      //   existedConfig.forEach((element) => {

      //     if (element.userName === window.sessionStorage.getItem("userid")) {
      //       if (
      //         element.configs.colorScheme !== null &&
      //         element.configs.colorScheme !== undefined &&
      //         element.configs.colorScheme !== ""
      //       ) {
      //         this.changeLayout("event", element.configs.colorScheme);
      //       } else {
      //         this.changeLayout("event", true);
      //       }
      //       if (
      //         element.configs.MenuColorsMode !== null &&
      //         element.configs.MenuColorsMode !== undefined &&
      //         element.configs.MenuColorsMode !== ""
      //       ) {
      //         this.changeMenuColorMode(
      //           "event",
      //           element.configs.MenuColorsMode
      //         );
      //       } else {
      //         this.changeMenuColorMode("event", "dark");
      //       }
      //       if (
      //         element.configs.MenuColor !== null &&
      //         element.configs.MenuColor !== undefined &&
      //         element.configs.MenuColor !== ""
      //       ) {
      //         this.changeMenuColor("event", element.configs.MenuColor);
      //       } else {
      //         this.changeMenuColor("event", "blue");
      //       }
      //       if (
      //         element.configs.Themes !== null &&
      //         element.configs.Themes !== undefined &&
      //         element.configs.Themes !== ""
      //       ) {
      //         this.changeComponentTheme("event", element.configs.Themes);
      //       } else {
      //         this.changeComponentTheme("event", "blue");
      //       }
      //       if (
      //         element.configs.inputStyle !== null &&
      //         element.configs.inputStyle !== undefined &&
      //         element.configs.inputStyle !== ""
      //       ) {
      //         this.app.inputStyle = element.configs.inputStyle;
      //       } else {
      //         this.app.inputStyle = "outlined";
      //       }
      //       if (
      //         element.configs.rippleEffect !== null &&
      //         element.configs.rippleEffect !== undefined &&
      //         element.configs.rippleEffect !== ""
      //       ) {
      //         this.app.ripple = element.configs.rippleEffect;
      //       } else {
      //         this.app.ripple = false;
      //       }
      //       if (
      //         element.configs.menuType !== null &&
      //         element.configs.menuType !== undefined &&
      //         element.configs.menuType !== ""
      //       ) {
      //         this.app.horizontalMenu = element.configs.menuType;
      //       } else {
      //         this.app.horizontalMenu = false;
      //       }
      //     }
      //   });
      // } else {
      //   this.changeLayout("event", true);
      //   this.changeMenuColorMode("event", "dark");
      //   this.changeComponentTheme("event", "blue");
      //   this.app.horizontalMenu = false;
      //   this.app.inputStyle = "outlined";
      // }
    }
  }
  // closesettings() {
  //   document
  //     .getElementById("layout-config")
  //     .classList.remove("layout-config-active");
  // }
  // closeUpdates() {
  //   document
  //     .getElementById("layout-config-updates")
  //     .classList.remove("layout-config-active");
  // }
  // changeLayout(event, mode) {
  //   this.app.darkMode = mode;
  //   if (mode === true) {
  //     this.app.menuColorMode = "dark";
  //     this.app.menuColor = "layout-menu-dark";
  //     this.app.layoutColor = this.darkColors[0].file;
  //     this.menuColors = this.darkColors;
  //     this.changeLightDarkLayout(
  //       "layout-css",
  //       this.darkColors[0].file,
  //       "layout-dark"
  //     );
  //     this.changeLightDarkTheme("theme-css", "theme-dark");
  //     document
  //       .getElementById("sidelogo_img")
  //       .setAttribute("src", "assets/layout/images/flexivanlogo_dark.png");
  //     document
  //       .getElementById("sidelogo_img_mini")
  //       .setAttribute("src", "assets/layout/images/aim360_dark.png");
  //     document
  //       .getElementById("toplogo_img")
  //       .setAttribute("src", "assets/layout/images/flexivanlogo_dark.png");
  //   } else {
  //     this.app.menuColorMode = "light";
  //     this.app.menuColor = "layout-menu-light";
  //     this.app.layoutColor = this.lightColors[0].file;
  //     this.menuColors = this.lightColors;
  //     this.changeLightDarkLayout(
  //       "layout-css",
  //       this.lightColors[0].file,
  //       "layout-light",
  //     );
  //     this.changeLightDarkTheme("theme-css", "theme-light");
  //     document
  //       .getElementById("sidelogo_img")
  //       .setAttribute("src", "assets/layout/images/flexivanlogo_light.png");
  //     document
  //       .getElementById("sidelogo_img_mini")
  //       .setAttribute("src", "assets/layout/images/aim360_light.png");
  //     document
  //       .getElementById("toplogo_img")
  //       .setAttribute("src", "assets/layout/images/flexivanlogo_light.png");
  //   }
  //   ///===========New=============//
  //   let existedConfigDUp = JSON.parse(localStorage.getItem("layoutConfig"));
  //   if (Array.isArray(existedConfigDUp) && existedConfigDUp != null) {
  //     let existedArray = existedConfigDUp;
  //     let isExist = existedConfigDUp.some((element) => {
  //       return element.userName === window.sessionStorage.getItem("userid");
  //     });
  //     if (isExist) {
  //       existedArray.forEach((element) => {
  //         if (element.userName === window.sessionStorage.getItem("userid")) {
  //           element.configs.MenuColorsMode = this.app.menuColorMode;
  //         }
  //       });
  //       localStorage.setItem("layoutConfig", JSON.stringify(existedArray));
  //     } else {
  //       this._setNewUser_Configs("MenuColorsMode", this.app.menuColorMode);
  //     }
  //   } else {
  //     this._setNewUser_Configs("MenuColorsMode", this.app.menuColorMode);
  //   }
  //   //=========New========//
  //   let existedConfig = JSON.parse(localStorage.getItem("layoutConfig"));
  //   if (Array.isArray(existedConfig) && existedConfig != null) {
  //     let existedArray = existedConfig;
  //     let isExist = existedConfig.some((element) => {
  //       return element.userName === window.sessionStorage.getItem("userid");
  //     });
  //     if (isExist) {
  //       existedArray.forEach((element) => {
  //         if (element.userName === window.sessionStorage.getItem("userid")) {
  //           element.configs.colorScheme = mode;
  //         }
  //       });
  //       localStorage.setItem("layoutConfig", JSON.stringify(existedArray));
  //     } else {
  //       this._setNewUser_Configs("colorScheme", mode);
  //     }
  //   } else {
  //     this._setNewUser_Configs("colorScheme", mode);
  //   }
  //   if (event !== "event") {
  //     event.preventDefault();
  //   }
  // }

  // changeLightDarkTheme(id, value) {

  //   const element = document.getElementById(id);
  //   const urlTokens = element.getAttribute("href").split("/");
  //   urlTokens[urlTokens.length - 1] = value + ".css";
  //   const newURL = urlTokens.join("/");

  //   this.replaceLink(element, newURL);
  // }

  // changeLightDarkLayout(id, color, mode, reload = false) {
  //   const element = document.getElementById(id);
  //   const urlTokens = element.getAttribute("href").split("/");
  //   urlTokens[urlTokens.length - 2] = color;
  //   urlTokens[urlTokens.length - 1] = mode + ".css";
  //   const newURL = urlTokens.join("/");

  //   this.replaceLink(element, newURL, reload);
  // }

  // changeMenuColorMode(event, mode,reload=false) {
  //   this.reload = reload
  //   this.app.menuColorMode = mode;
  //   if(mode === 'light') {
  //       this.sidePannelColors = true;
  //       document
  //       .getElementById("sidelogo_img")
  //       .setAttribute("src", "assets/layout/images/flexivanlogo_light.png");
  //     document
  //       .getElementById("sidelogo_img_mini")
  //       .setAttribute("src", "assets/layout/images/aim360_light.png");
  //     document
  //       .getElementById("toplogo_img")
  //       .setAttribute("src", "assets/layout/images/flexivanlogo_light.png");
  //       // localStorage.setItem('')
  //       if(this.app.darkMode){
  //         document
  //         .getElementById("toplogo_img")
  //         .setAttribute("src", "assets/layout/images/flexivanlogo_dark.png");
  //   }
  //   }else{
  //       this.sidePannelColors = false;
  //       document
  //       .getElementById("sidelogo_img")
  //       .setAttribute("src", "assets/layout/images/flexivanlogo_dark.png");
  //     document
  //       .getElementById("sidelogo_img_mini")
  //       .setAttribute("src", "assets/layout/images/aim360_dark.png");

  //       document
  //       .getElementById("toplogo_img")
  //       .setAttribute("src", "assets/layout/images/flexivanlogo_light.png");
  //       if(this.app.darkMode){
  //         document
  //         .getElementById("toplogo_img")
  //         .setAttribute("src", "assets/layout/images/flexivanlogo_dark.png");
  //   }

  //   }
  //   if (mode !== "custom") {
  //     if (mode === "light") {
  //       this.menuColors = this.lightColors;
  //       this.changeMenuColor(event, this.lightColors[0].file);
  //     } else {
  //       this.menuColors = this.darkColors;
  //       this.changeMenuColor(event, this.darkColors[0].file);
  //     }
  //   } else {
  //     this.menuColors = this.customColors;
  //     this.changeMenuColor(event, this.customColors[0].file);
  //   }

  //   let existedConfig = JSON.parse(localStorage.getItem("layoutConfig"));
  //   if (Array.isArray(existedConfig) && existedConfig != null) {
  //     let existedArray = existedConfig;
  //     let isExist = existedConfig.some((element) => {
  //       return element.userName === window.sessionStorage.getItem("userid");
  //     });
  //     if (isExist) {
  //       existedArray.forEach((element) => {
  //         if (element.userName === window.sessionStorage.getItem("userid")) {
  //           element.configs.MenuColorsMode = mode;
  //         }
  //       });
  //       localStorage.setItem("layoutConfig", JSON.stringify(existedArray));
  //     } else {
  //       this._setNewUser_Configs("MenuColorsMode", mode);
  //     }
  //   } else {
  //     this._setNewUser_Configs("MenuColorsMode", mode);
  //   }

  //   if (event !== "event") {
  //     event.preventDefault();
  //   }
  // }

  // changeMenuColor(event, color,reloadValue = false) {
  //   // this.reload= true
  //   if (this.app.menuColorMode !== "custom") {
  //     this.app.menuColor = "layout-menu-" + this.app.menuColorMode;
  //     if (this.app.menuColorMode === "dark") {
  //       this.app.layoutColor = color;
  //       // this.reload= true
  //       this.changeStyleSheetsColor("layout-css", color,reloadValue);
  //     } else {
  //       this.app.layoutColor = color;
  //       // this.reload= true
  //       this.changeStyleSheetsColor("layout-css", color,reloadValue);
  //     }
  //   } else {

  //       // this.reload= true
  //     this.app.layoutColor = color;
  //     this.app.menuColor = "layout-menu-" + color;
  //   }

  //   let existedConfig = JSON.parse(localStorage.getItem("layoutConfig"));
  //   if (Array.isArray(existedConfig) && existedConfig != null) {
  //     let existedArray = existedConfig;
  //     let isExist = existedConfig.some((element) => {
  //       return element.userName === window.sessionStorage.getItem("userid");
  //     });
  //     if (isExist) {

  //       existedArray.forEach((element) => {
  //         if (element.userName === window.sessionStorage.getItem("userid")) {

  //           element.configs.MenuColor = color;
  //         }
  //       });
  //       localStorage.setItem("layoutConfig", JSON.stringify(existedArray));
  //     } else {
  //       this._setNewUser_Configs("MenuColor", color);
  //     }
  //   } else {
  //     this._setNewUser_Configs("MenuColor", color);
  //   }

  //   if (event != "event") {
  //     event.preventDefault();
  //   }
  // }

  // changeComponentTheme(event, color,reloadValue=false) {

  //   this.app.themeColor = color;
  //   this.changeStyleSheetsColor("theme-css", color,reloadValue);
  //   let existedConfig = JSON.parse(localStorage.getItem("layoutConfig"));
  //   if (Array.isArray(existedConfig) && existedConfig != null) {
  //     let existedArray = existedConfig;
  //     let isExist = existedConfig.some((element) => {
  //       return element.userName === window.sessionStorage.getItem("userid");
  //     });
  //     if (isExist) {
  //       existedArray.forEach((element) => {
  //         if (element.userName === window.sessionStorage.getItem("userid")) {
  //           element.configs.Themes = color;
  //         }
  //       });
  //       localStorage.setItem("layoutConfig", JSON.stringify(existedArray));
  //     } else {
  //       this._setNewUser_Configs("Themes", color);
  //     }
  //   } else {
  //     this._setNewUser_Configs("Themes", color);
  //   }
  //   if (event !== "event") {
  //     event.preventDefault();
  //   }
  // }

  // changeStyleSheetsColor(id, value,reloadValue=false) {

  //   const element = document.getElementById(id);
  //   const urlTokens = element.getAttribute("href").split("/");
  //   urlTokens[urlTokens.length - 2] = value;
  //   let newURL = urlTokens.join("/");
  //   this.sidePannelColors === true ? newURL = 'assets/layout/css/blue/layout-light.css': newURL='assets/layout/css/blue/layout-dark.css';
  //   this.replaceLink(element, newURL,reloadValue);
  // }

  // onConfigButtonClick(event) {

  //   this.app.configActive = !this.app.configActive;
  //   event.preventDefault();
  // }
  // re;

  // onConfigCloseClick(event) {

  //   this.app.configActive = false;
  //   event.preventDefault();
  // }

  // replaceLink(linkElement, href, reload = false) {
  //     this.reloadPage = reload
  //   if (this.isIE()) {
  //     linkElement.setAttribute("href", href);
  //   } else {

  //     const id = linkElement.getAttribute("id");
  //     const cloneLinkElement = linkElement.cloneNode(true);

  //     cloneLinkElement.setAttribute("href", href);
  //     cloneLinkElement.setAttribute("id", id + "-clone");

  //     linkElement.parentNode.insertBefore(
  //       cloneLinkElement,
  //       linkElement.nextSibling
  //     );

  //     if( this.reloadPage === true){
  //       this.reloadPage= false;
  //   }
  //     cloneLinkElement.addEventListener("load", () => {

  //       linkElement.remove();
  //       cloneLinkElement.setAttribute("id", id);
  //       if(this.reload === true){
  //           this.applyTheme();
  //           this.reload= false;
  //       }
  //     });
  //   }
  // }

  // isIE() {
  //   return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  // }

  // _setNewUser_Configs(configName, value) {
  //   let obj = {
  //     userName: window.sessionStorage.getItem("userid"),
  //     configs: {},
  //   };
  //   obj.configs[configName] = value;
  //   this.userConfigs.push(obj);
  //   localStorage.setItem("layoutConfig", JSON.stringify(this.userConfigs));
  // }

  // applyThems(linkElement, cloneLinkElement, id) {
  //   linkElement.remove();
  //   cloneLinkElement.setAttribute("id", id);
  // }

  // _onMenuTypeChange() {
  //   let existedConfig = JSON.parse(localStorage.getItem("layoutConfig"));
  //   if (Array.isArray(existedConfig) && existedConfig != null) {
  //     let existedArray = existedConfig;
  //     let isExist = existedConfig.some((element) => {
  //       return element.userName === window.sessionStorage.getItem("userid");
  //     });
  //     if (isExist) {
  //       existedArray.forEach((element) => {
  //         if (element.userName === window.sessionStorage.getItem("userid")) {
  //           element.configs.menuType = this.app.horizontalMenu;
  //         }
  //       });
  //       localStorage.setItem("layoutConfig", JSON.stringify(existedArray));
  //     } else {
  //       this._setNewUser_Configs("menuType", this.app.horizontalMenu);
  //     }
  //   } else {
  //     this._setNewUser_Configs("menuType", this.app.horizontalMenu);
  //   }
  // }

  // applyTheme() {
  //   window.location.reload();
  // }

  // dataChanged(event, value) {
  //   this.reload = true;
  // }
  // _onInputStyleChange() {
  //   let existedConfig = JSON.parse(localStorage.getItem("layoutConfig"));
  //   if (Array.isArray(existedConfig) && existedConfig != null) {
  //     let existedArray = existedConfig;
  //     let isExist = existedConfig.some((element) => {
  //       return element.userName === window.sessionStorage.getItem("userid");
  //     });
  //     if (isExist) {
  //       existedArray.forEach((element) => {
  //         if (element.userName === window.sessionStorage.getItem("userid")) {
  //           element.configs.inputStyle = this.app.inputStyle;
  //         }
  //       });
  //       localStorage.setItem("layoutConfig", JSON.stringify(existedArray));
  //     } else {
  //       this._setNewUser_Configs("inputStyle", this.app.inputStyle);
  //     }
  //   } else {
  //     this._setNewUser_Configs("inputStyle", this.app.inputStyle);
  //   }
  // }

  // _onCHangeRippleEffect() {
  //   let existedConfig = JSON.parse(localStorage.getItem("layoutConfig"));
  //   if (Array.isArray(existedConfig) && existedConfig != null) {
  //     let existedArray = existedConfig;
  //     let isExist = existedConfig.some((element) => {
  //       return element.userName === window.sessionStorage.getItem("userid");
  //     });
  //     if (isExist) {
  //       existedArray.forEach((element) => {
  //         if (element.userName === window.sessionStorage.getItem("userid")) {
  //           element.configs.rippleEffect = this.app.ripple;
  //         }
  //       });
  //       localStorage.setItem("layoutConfig", JSON.stringify(existedArray));
  //     } else {
  //       this._setNewUser_Configs("rippleEffect", this.app.ripple);
  //     }
  //   } else {
  //     this._setNewUser_Configs("rippleEffect", this.app.ripple);
  //   }
  // }

  // ngAfterViewInit() {

  // }
}
