import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { APIMSALConfigsService } from "./app/services/apiConfigs/api-msal-configs.service";
import { environment } from "./environments/environment";

// if (environment.production) {
//   enableProdMode();
// }

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () {};
  }
}
fetch("assets/demo/data/office365-config.json")
  .then((response) => {
    return response.json();
  })
  .then((data) => {
    // console.log("APIMSALConfigsService***************---: ", data);
    APIMSALConfigsService.setConfigData(data);
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(
        (err) => {
          return err;
        }
        // console.log(err)
      );
  });

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch((err) => console.error(err));
