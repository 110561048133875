import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as CryptoJS from "crypto-js";
@Injectable()
export class EnvconfigService {
  private appConfig: any;
  appContent: any;
  constructor(private http: HttpClient) {}

  // async doSomething() {
  //   this.response = await this.longRunningMethod();
  // }

  // async longRunningMethod() {
  //   let response = await this._http.get<any>(this.apiURL).delay(1000).toPromise();
  //   return response;
  // }

  // async loadConfig() {
  //   let response = await this.getUrl();
  // }

  // async getUrl() {
  //   let response = await this.http
  //     .get<any>("assets/demo/data/environmentConfig.json")
  //     .toPromise()
  //     .then((response) => {
  //       var Data = CryptoJS.AES.decrypt(
  //         decodeURIComponent(response?.data),
  //         "Flexiv@n_C@ms2.0"
  //       );
  //       let decryptedInfo = JSON.parse(Data.toString(CryptoJS.enc.Utf8));
  //       this.appConfig = decryptedInfo.data;
  //       console.log(this.appConfig, "tgyhuji");

  //       return response;
  //     });
  //   return response;
  // }

  // getConfig() {
  //   return this.appConfig;
  // }
  loadContent() {
    debugger;
    return this.http
      .get("assets/demo/data/contents_config.json")
      .toPromise()
      .then((res) => {
        this.appContent = res;
        // console.log("this.appConfig this.appConfig",this.appConfig)
      });
  }

  getContent() {
    return this.appContent;
  }
}
