import { Component, Inject, forwardRef } from "@angular/core";

@Component({
  selector: "app-footer",
  template: `
    <p class="footer-information">
      Copyright &copy; Flexi-Van Leasing LLC. Powered by AIM360
    </p>
  `,
})
export class AppFooterComponent {}
