import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../auth-guard/auth.service";
import { environment } from "../../environments/environment.prod";
import { MSAL_GUARD_CONFIG } from "../msal/constants";
import { MsalGuardConfiguration } from "../msal/msal.guard.config";
import { MsalBroadcastService, MsalService } from "../msal";
import jwt_decode from "jwt-decode";
import {
  EventMessage,
  EventType,
  InteractionType,
  AuthenticationResult,
} from "@azure/msal-browser";
import { SharedDataService } from "../services/data/shared-data.service";
@Component({
  selector: "app-login",
  //templateUrl: './app.login.component.html',
  templateUrl: "./app.testlogin.component.html",
})
export class AppLoginComponent {
  dark: boolean;

  checked: boolean;
  loginform: FormGroup;
  imgurl: any;
  version: string;
  loggedIn = false;
  showSpinner: boolean;
  spinnerArray = [false, false, false];
  username: string = "";
  userdtl: any;
  intermediateJson: any;
  nav_first_msg: any;
  nav_middle_msg: any;
  nav_last_msg: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authservice: AuthService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private DataService: SharedDataService
  ) {
    this.loginform = this.fb.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  ngOnInit() {
    setInterval(() => {
      var imgarr = [
        "assets/layout/images/fccp_bg2-min.jpg",
        "assets/layout/images/fccp_bg1-min.jpg",
        "assets/layout/images/fccp_bg3.png",
        "assets/layout/images/fccp_bg-min.jpg",
      ];
      this.imgurl = imgarr[Math.floor(Math.random() * imgarr.length)];
    }, 5000);
    this.version = environment.version;

    this.loginWithOfficeAccount();
  }

  login() {
    // this.router.navigateByUrl('/home');
    // this.showSpinner = true
    //
    this.DataService.isloginLoaderEnabled.subscribe((value) => {
      this.spinnerArray[0] = value;
    });
    this.authservice.login(
      this.loginform.get("username").value,
      this.loginform.get("password").value
    );
  }

  async loginWithOfficeAccount() {
    let mskeys: any = [];
    await this.authservice.readmskeys().then((data) => {
      if (data["keys"].length > 0) {
        mskeys = data;
      }
    });
    // this.spinnerArray[0] = true
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest })
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.checkAccount();
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            var decodetoken = jwt_decode(response.idToken, { header: true });
            var mstoken = this.findmstoken(mskeys, decodetoken["kid"]);
            this.authservice.login(
              response.account.username,
              "",
              "office365",
              response.idToken,
              mstoken
            );
            this.checkAccount();
          });
      }
    } else {
      this.DataService.enableLoginLoaderState(false);
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest });
      } else {
        this.authService.loginRedirect();
      }
    }
  }
  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  findmstoken(mskeys, kid) {
    var indx = mskeys.keys.findIndex((key) => key.kid == kid);
    var mstoken = "";
    if (indx > -1) {
      mstoken = mskeys.keys[indx].x5c[0];
    }
    return mstoken;
  }
  signout() {
    this.username = "";
    this.authservice.logout();
  }
}
