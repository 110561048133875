
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class APIMSALConfigsService {
  public static appConfig: any;
  constructor() { }

  // public _http:HttpClient
  // async  loadConfig(){
  
  //   return await this._http.get("assets/data/apiConfigs.json").toPromise().then(res => {
  //     APIMSALConfigsService.appConfig  = res ;
  //                console.log("this.appConfig this.appConfig",APIMSALConfigsService.appConfig)
  //   })
  // }

  static setConfigData(data ){
    APIMSALConfigsService.appConfig =data ;
  }

 public static getConfig(){
    
    return APIMSALConfigsService.appConfig;
  }
}
