import { AuthService } from 'src/app/auth-guard/auth.service';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
   } from '@angular/common/http';
   import { Observable, throwError } from 'rxjs';
   import { retry, catchError, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
   
   @Injectable()
   export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService){}
    spinnerArray = [false, false, false];
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {      
      return next.handle(request)
        .pipe(          
          catchError((error: HttpErrorResponse) => {
            
              if(error.status === 403){
                this.authService.showtoast("error", error.error);
                setTimeout(() => {
                    location.reload();
                  this.authService.logout();
                }, 2500);
                  
              }else if(error.status === 0){                
                // this.authService.showtoast("error", "Technical error");
              }
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            return throwError(errorMessage);
          })
        )
    }
   }